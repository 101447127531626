import React, { useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";

import Info from "@templates/Info";
import ContentBox from "@atoms/ContentBox";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import AboutSvg from "@images/icons/team.svg";

const AboutPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Info active={startPage}>
      <ContentBox>
        <h2>
          <Icon src={AboutSvg} /> About fplrank.live
        </h2>
      </ContentBox>
      <ContentBox>
        <Text>
          <h3>Welcome to the heart of FPL excitement! 🚀</h3>
          <p>
            We're thrilled that you're here, eager to dive into the world of
            fplrank.live and discover how it can transform your Fantasy Premier
            League experience.
          </p>
          <p>
            Fplrank.live is a game-changer for Fantasy Premier League
            enthusiasts. It started as a tool for one mini league, seamlessly
            consolidating live GW points, captaincy, transfers, and more in one
            view.
          </p>
          <p>
            At fplrank.live, our story begins with a passion for Fantasy Premier
            League and the desire to elevate the gaming experience for every
            mini league out there. What started as a tool for our own mini
            league with a handful of mates quickly evolved into something much
            more—a game-changer that we couldn't keep to ourselves.
          </p>

          <h3>The Genesis</h3>
          <p>
            Fplrank.live emerged from the need to simplify the FPL chaos.
            Picture this: your mini league, the banter, the competition. Now
            imagine having all the crucial FPL details—live GW points,
            captaincy, transfers, and more—neatly bundled into one seamless
            view. That was the spark that ignited fplrank.live.
          </p>

          <h3>Beyond Basics</h3>
          <p>
            As we delved deeper into the world of FPL, we weren't satisfied with
            just the basics. We wanted more excitement, more strategy, and more
            camaraderie. So, we amped it up with features like ownership
            breakdowns, player-specific owners, transfer costs, bench points,
            and charts. And guess what? This is just the beginning!
          </p>

          <h3>What’s next?</h3>
          <p>
            The story of fplrank.live is an ongoing journey. We're committed to
            pushing the boundaries, making FPL mini leagues not just competitive
            but downright thrilling. Stay tuned for more features, more
            excitement, and more fun!
          </p>

          <p>
            Join us in rewriting the narrative of Fantasy Premier League with
            fplrank.live. Your league is about to level up! ⚽🌟
          </p>
        </Text>
      </ContentBox>
    </Info>
  );
};

export default AboutPage;
