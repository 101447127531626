import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  ${({ small }) => small && "max-width: 400px;"}
  margin: 0;
  padding: 24px;
  background-color: var(--white);
  ${({ clickable }) => clickable && "cursor: pointer;"}

  & + & {
    margin: var(--padding0) 0 0;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ small }) => (small ? "center" : "space-between")};
    margin: 0 0 24px;
    height: 40px;
    ${({ small }) => small && "text-align: center;"}
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 22px;
    font-weight: 500;

    svg {
      margin-right: 8px;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 18px;
    font-weight: 500;

    svg {
      margin-right: 8px;
    }
  }

  ol {
    margin: 0 0 16px;
    list-style: number;

    li {
      margin: 0 0 16px 12px;
      padding: 0 0 0 12px;
    }
  }

  ul {
    margin: 16px 0;
    li {
      margin: 0 0 16px;
      padding: 0;
    }
  }

  a {
    cursor: pointer;
  }

  img {
    width: 100%;
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 0;
    height: 40px;

    a + button,
    button + a,
    a + a,
    button + button {
      margin-left: 10px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    box-shadow: 0 30px 45px -25px rgba(22, 22, 22, 0.15);
    border-radius: 4px;
    padding: 32px;

    & > *:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    & > *:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & + & {
      margin: var(--padding1) 0 0;
    }
  }
`;
