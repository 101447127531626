import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Main, Aside } from "./styles.js";

const Grid = ({ aside, stickyAside = false, fullWidth = false, children }) => {
  return (
    <Wrapper fullWidth={fullWidth}>
      <Main>{children}</Main>
      <Aside stickyAside={stickyAside}>{aside}</Aside>
    </Wrapper>
  );
};

Grid.propTypes = {
  aside: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  stickyAside: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Grid;
