import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const ContentBox = ({ id, small, onClick, className, children }) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Wrapper
      id={id}
      small={small}
      clickable={onClick ? true : false}
      onClick={handleClick}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

ContentBox.propTypes = {
  small: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ContentBox;
