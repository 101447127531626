import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--padding0);
  width: 100%;

  h4 {
    margin: 0 0 24px;
    font-size: 22px;
    text-align: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    gap: var(--padding1);
    ${({ fullWidth }) => !fullWidth && "grid-template-columns: 3fr 1fr;"}
  }
`;

export const Main = styled.section`
  overflow: hidden;
`;

export const Aside = styled.aside`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    > section {
      ${({ stickyAside }) => stickyAside && "position: sticky; top: 84px;"}
    }
  }
`;
