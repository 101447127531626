import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import ContentBox from "@atoms/ContentBox";
import Grid from "@atoms/Grid";
import Icon from "@atoms/Icon";
import HorWrap from "@atoms/HorWrap";

import AboutSvg from "@images/icons/team.svg";
import FaqSvg from "@images/icons/question-mark-circle.svg";
import TextSvg from "@images/icons/file-text.svg";
import LockSvg from "@images/icons/lock.svg";
import CookiesSvg from "@images/icons/cookies.svg";
import PaperPlaneSvg from "@images/icons/paper-plane.svg";
import XSvg from "@images/icons/x-social.svg";
import LeftChevronSvg from "@images/icons/chevron/left.svg";

import { MainContentWrapper } from "./styles.js";

const Info = ({ active, children }) => {
  const links = [
    {
      id: "/about",
      icon: AboutSvg,
      name: "About",
    },
    {
      id: "/faq",
      icon: FaqSvg,
      name: "FAQ",
    },
    {
      id: "/terms",
      icon: TextSvg,
      name: "Terms and conditions",
    },
    {
      id: "/privacy",
      icon: LockSvg,
      name: "Privacy policy",
    },
    {
      id: "/cookies",
      icon: CookiesSvg,
      name: "Cookies",
    },
    {
      id: "/contact",
      icon: PaperPlaneSvg,
      name: "Contact",
    },
    {
      id: "x",
      href: "https://x.com/FPLRank_live",
      icon: XSvg,
      name: "Follow us on X",
    },
    {
      id: "/",
      icon: LeftChevronSvg,
      name: "back to landing",
    },
  ];

  return (
    <MainContentWrapper active={active}>
      <HorWrap>
        <Grid
          stickyAside
          aside={
            <>
              <ContentBox>
                <ul>
                  {links.map((link) => (
                    <li key={link.id}>
                      {link.href ? (
                        <a href={link.href} target="_blank">
                          <h3>
                            <Icon src={link.icon} /> {link.name}
                          </h3>
                        </a>
                      ) : (
                        <Link to={link.id}>
                          <h3>
                            <Icon src={link.icon} /> {link.name}
                          </h3>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </ContentBox>
            </>
          }
        >
          {children}
        </Grid>
      </HorWrap>
    </MainContentWrapper>
  );
};

Info.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Info;
